import Button from 'components/Button/Button';
import Card from 'components/Card';
import Checkbox from 'components/FormElement/CheckBox';
import CreatableReactSelect from 'components/FormElement/CreatableReactSelect';
import DatePicker from 'components/FormElement/datePicker';
import DropZone from 'components/FormElement/DropZoneField';
import { EnumFileType, fileInputEnum } from 'components/FormElement/enum';
import ErrorMessage from 'components/FormElement/ErrorMessage';
import InputField from 'components/FormElement/InputField';
import ReactSelect from 'components/FormElement/ReactSelect';
import { IOptions } from 'components/FormElement/types';
import ReactEditor from 'components/ReactQuillEditor/ReactQuillEditor';
import { KeyLearningOptions, Roles } from 'constants/common.constant';
import { addMonths } from 'date-fns';
import { Form, Formik } from 'formik';
import { useAxiosGet, useAxiosPost } from 'hooks/useAxios';
import { WEEKDAYS } from 'modules/Course/Admin/constants';
import { useCommonAslLevel } from 'modules/Course/common/hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUser } from 'reduxStore/slices/authSlice';
import { SetFieldValue } from 'types';
import {
  calculateDaysInfo,
  checkIfNotEditable,
  commonHandleCheckBoxFunction,
  getISOString,
  mergeDateAndTime,
  removeEmptyKeys,
} from '../../helper/form.helper';
import { CourseBasicDetailDataTypes, InPersonBasicTypes } from '../../types';
import { InPersonValidations } from '../../validations';

interface InPersonStepOneProps {
  initialData: { data: CourseBasicDetailDataTypes };
  onSubmit: (data: InPersonBasicTypes) => void;
  isLoading: boolean;
}

const InPersonStepOne: FC<InPersonStepOneProps> = ({
  onSubmit,
  initialData,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [callApi] = useAxiosGet();
  const { aslLevel: levels, getASLLevelData: refetchData } = useCommonAslLevel();
  const { slug } = useParams();
  const [createAslLevel, { isLoading: creatingLevel }] = useAxiosPost();
  const [startDate, setStartDate] = useState<Date>();
  const [startTime, setStartTime] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [endTime, setEndTime] = useState<Date>();
  const [intervalDays, setIntervalDays] = useState<Array<number>>();
  const userFromRedux = useSelector(getCurrentUser)?.role?.role ?? Roles.Admin;
  const [invitedEditors, setInvitedEditors] = useState<IOptions[]>();
  const [isAdmin, setIsAdmin] = useState<boolean>(userFromRedux === Roles.Admin);
  const [keyLearningOptions, setKeyLearningOptions] = useState(KeyLearningOptions);
  const initialValues = {
    cover_video: initialData.data.cover_video ?? null,
    cover_image: initialData.data.cover_image ?? null,
    srt_file_path: initialData.data.srt_file_path ?? null,
    description: initialData.data.description ?? '',
    title: initialData.data.title ?? '',
    asl_level_id: initialData.data.asl_level_id ?? '',
    key_learnings: initialData.data.key_learnings ?? [],
    subscription_type_id: initialData.data.subscription_type_id ?? '',
    address: initialData.data.address ?? '',
    city: initialData.data.city ?? '',
    zip_code: initialData.data.zip_code ?? '',
    country: initialData.data.country ?? '',
    start_date: initialData.data.start_date ?? null,
    end_date: initialData.data.end_date ?? null,
    start_time: initialData.data.start_time
      ? getISOString(String(initialData.data.start_time))
      : null,
    end_time: initialData.data.end_time
      ? getISOString(String(initialData.data.end_time))
      : null,

    price: initialData.data.price ?? '',
    max_participants: initialData.data.max_participants ?? 0,
    min_participants: initialData.data.min_participants ?? 0,
    repeat_interval_days: initialData.data.repeat_interval_days ?? [],
    editor_teacher_id:
      initialData.data?.invited_course_editors?.map(
        (editor) => editor.user_teacher_id
      ) ?? [],
    user_teacher_id: initialData.data.user_teacher_id ?? '',
  };
  const isDateEditable = !!slug && startDate && checkIfNotEditable(startDate);

  const getCourseEditors = async (
    start_date: Date,
    start_time: Date,
    end_date: Date,
    end_time: Date,
    intervalsDays: number[]
  ) => {
    const response = await callApi('/role/get-all');
    const teacherId = response.data.find(
      (item: { role: string; id: string }) => item.role === Roles.Teacher
    );
    const { data, error } = await callApi('/courses/teacher', {
      params: {
        view: true,
        role: teacherId.id,
        start_date: mergeDateAndTime(start_date, start_time),
        end_date: mergeDateAndTime(end_date, end_time),
        start_time: start_time.toISOString().substring(11, 19),
        end_time: end_time.toISOString().substring(11, 19),
        interval_days: intervalsDays?.toString(),
      },
    });
    if (data && !error) {
      setInvitedEditors(data);
    }
  };

  useEffect(() => {
    if (initialValues) {
      const start_date = initialValues.start_date
        ? new Date(initialValues.start_date)
        : undefined;
      const start_time = initialValues.start_time
        ? new Date(initialValues.start_time)
        : undefined;
      const end_date = initialValues.end_date
        ? new Date(initialValues.end_date)
        : undefined;
      const end_time = initialValues.end_time
        ? new Date(initialValues.end_time)
        : undefined;
      setIntervalDays(initialValues.repeat_interval_days);
      setStartDate(start_date);
      setStartTime(start_time);
      setEndDate(end_date);
      setEndTime(end_time);
    }
  }, []);

  useEffect(() => {
    if (startDate && startTime && endDate && endTime && intervalDays) {
      getCourseEditors(startDate, startTime, endDate, endTime, intervalDays);
    }
  }, [startDate, startTime, endDate, endTime, intervalDays]);

  useEffect(() => {
    setIsAdmin(userFromRedux === Roles.Admin);
  }, [userFromRedux]);

  const handleAslCreateOption = async (
    value: string,
    setFieldValue: SetFieldValue
  ) => {
    const { error, data } = await createAslLevel(`/asl`, { level: value });
    if (!error) {
      refetchData(true);
      setFieldValue(`asl_level_id`, data?.id);
    }
  };

  const handleKeyLearningCreation = (
    value: string,
    setFieldValue: SetFieldValue,
    oldKeys: string[]
  ) => {
    setFieldValue(`key_learnings`, [...oldKeys, value]);
    setKeyLearningOptions((prev) => [{ label: value, value }, ...prev]);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={InPersonValidations(isAdmin)}
      onSubmit={(values) => {
        const updatedData = {
          ...values,
          ...(values.start_time && values.end_time
            ? {
                start_time: new Date(values?.start_time)
                  .toISOString()
                  .substring(11, 19),

                end_time: new Date(values?.end_time).toISOString().substring(11, 19),
              }
            : {}),
        };

        const nonEmptyValue = removeEmptyKeys(updatedData);
        onSubmit(nonEmptyValue);
      }}
    >
      {({ values, setFieldValue, setFieldTouched }) => {
        const { differenceInDays, validDays } = calculateDaysInfo(
          String(values.start_date),
          String(values.end_date)
        );

        return (
          <Card isGray className="course-inner-card">
            <div className="course-card-title">
              {' '}
              {t('CourseManagement.AddEditForm.BasicFormHeader')}
            </div>
            <Form className="">
              <div className="row">
                <div className="left-part">
                  <DropZone
                    fileInputIcon="camera"
                    name="cover_image"
                    setValue={setFieldValue}
                    value={values.cover_image}
                    label={t('CourseManagement.AddEditForm.CoverPhotoLabel')}
                    SubTitle={t('CourseManagement.AddEditForm.CoverPhotoSubTitle')}
                    acceptTypes="image/*"
                    fileType={EnumFileType.Image}
                  />
                  <DropZone
                    fileInputIcon="pause"
                    name="cover_video"
                    setValue={setFieldValue}
                    value={values.cover_video}
                    label={t('CourseManagement.AddEditForm.CoverVideoLabel')}
                    SubTitle={t('CourseManagement.AddEditForm.CoverVideoSubTitle')}
                    acceptTypes="video/*"
                    fileType={EnumFileType.Video}
                    size={1024}
                  />
                  <DropZone
                    fileInputIcon="camera"
                    name="srt_file_path"
                    setValue={setFieldValue}
                    value={values.srt_file_path}
                    Title={t('CourseManagement.AddEditForm.SRTFileLabel')}
                    label="Upload caption (.SRT file)"
                    acceptTypes="image/*"
                    variant={fileInputEnum.LinkFileInput}
                  />
                </div>
                <div className="right-part">
                  <InputField
                    name="title"
                    label={t('CourseManagement.AddEditForm.TitleLabel')}
                    placeholder={t('CourseManagement.AddEditForm.TitlePlaceholder')}
                    value={values.title}
                    isCompulsory
                  />
                  <ReactEditor
                    label={t('CourseManagement.AddEditForm.DescriptionLabel')}
                    parentClass="h-unset"
                    name="description"
                    placeholder={t(
                      'CourseManagement.AddEditForm.DescriptionPlaceholder'
                    )}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    value={values?.description}
                    isCompulsory
                  />
                  <CreatableReactSelect
                    name="asl_level_id"
                    options={levels}
                    placeholder={t(
                      'CourseManagement.AddEditForm.CourseLevelPlaceholder'
                    )}
                    isLoading={creatingLevel}
                    handleCreateOption={(e: string) => {
                      handleAslCreateOption(e, setFieldValue);
                    }}
                    isCompulsory
                    label={t('CourseManagement.AddEditForm.CourseLevelLabel')}
                    parentClass="width-full"
                  />
                  <InputField
                    isCompulsory
                    name="address"
                    label={t('CourseManagement.AddEditForm.AddressLabel')}
                    placeholder={t(
                      'CourseManagement.AddEditForm.AddressPlaceholder'
                    )}
                    value={values.address}
                  />
                  <InputField
                    isCompulsory
                    name="city"
                    label={t('CourseManagement.AddEditForm.CityLabel')}
                    placeholder={t('CourseManagement.AddEditForm.CityPlaceholder')}
                    value={values.city}
                  />
                  {/* this is static but will be dynamic in future */}
                  <div className="cms-button half simple bg-transparent">
                    <ReactSelect
                      isCreatable
                      name="country"
                      options={[
                        { value: 'india', label: 'India' },
                        { value: 'usa', label: 'USA' },
                        { value: 'uk', label: 'UK' },
                      ]}
                      placeholder={t(
                        'CourseManagement.AddEditForm.CountryPlaceholder'
                      )}
                      label={t('CourseManagement.AddEditForm.CountryLabel')}
                      parentClass="width-full"
                      isCompulsory
                    />
                    <InputField
                      isCompulsory
                      name="zip_code"
                      label={t('CourseManagement.AddEditForm.ZipcodeLabel')}
                      placeholder={t(
                        'CourseManagement.AddEditForm.ZipcodePlaceholder'
                      )}
                      value={values.zip_code}
                    />
                  </div>
                  {/* this is static but will be dynamic in futurte */}
                  <CreatableReactSelect
                    options={keyLearningOptions}
                    placeholder={t(
                      'CourseManagement.AddEditForm.KeyLearningPlaceholder'
                    )}
                    handleCreateOption={(e) =>
                      handleKeyLearningCreation(
                        e,
                        setFieldValue,
                        values.key_learnings
                      )
                    }
                    label={t('CourseManagement.AddEditForm.KeyLearningLabel')}
                    name="key_learnings"
                    parentClass="width-full"
                    isMulti
                    isCompulsory
                  />
                  <InputField
                    isCompulsory
                    type="number"
                    name="price"
                    min={0}
                    label={t('CourseManagement.AddEditForm.PriceLabel')}
                    value={values.price}
                    placeholder={t('CourseManagement.AddEditForm.PricePlaceholder')}
                    prefix="$"
                  />
                  {isAdmin && (
                    <ReactSelect
                      options={invitedEditors ?? []}
                      placeholder={t(
                        'CourseManagement.AddEditForm.SelectTeacherLabel'
                      )}
                      label={t(
                        'CourseManagement.AddEditForm.SelectTeacherPlaceholder'
                      )}
                      name="user_teacher_id"
                      disabled={!(invitedEditors && invitedEditors?.length > 0)}
                      isCompulsory
                      parentClass="width-full"
                    />
                  )}
                  <div className="cms-button half">
                    <DatePicker
                      placeholder={t('CourseManagement.AddEditForm.DatePlaceholder')}
                      name="start_date"
                      isCompulsory
                      icon
                      disabled={isDateEditable}
                      label={t('CourseManagement.AddEditForm.StartDateLabel')}
                      selectedDate={
                        values.start_date ? new Date(values.start_date) : undefined
                      }
                      onChange={(date) => {
                        if (!setFieldValue || !date) return;
                        setFieldValue(`start_date`, date.toISOString());
                        setStartDate(date);
                        setFieldValue('repeat_interval_days', []);
                      }}
                      minDate={(() => {
                        const currentDate = new Date();
                        return addMonths(currentDate, 1);
                      })()}
                      maxDate={new Date(values?.end_date ?? '')}
                    />
                    <DatePicker
                      placeholder={t('CourseManagement.AddEditForm.DatePlaceholder')}
                      name="end_date"
                      isCompulsory
                      icon
                      disabled={isDateEditable}
                      label={t('CourseManagement.AddEditForm.EndDateLabel')}
                      selectedDate={
                        values.end_date ? new Date(values.end_date) : undefined
                      }
                      onChange={(date) => {
                        if (!setFieldValue || !date) return;
                        setFieldValue(`end_date`, date.toISOString());
                        setEndDate(date);
                        setFieldValue('repeat_interval_days', []);
                        setEndDate(date);
                      }}
                      minDate={new Date(values?.start_date ?? '')}
                    />
                    <div className="week-list-wrap">
                      <div className="week-list-title">
                        <p>{t('CourseManagement.AddEditForm.WeeklyRepeatLabel')}</p>
                      </div>
                      <div className="week-list">
                        {WEEKDAYS.map((days, i) => {
                          const isDisabled =
                            differenceInDays <= 8 && !validDays.includes(i); // Disable if day is not in the valid range
                          const isChecked = values.repeat_interval_days.includes(i); // Check if the current day is checked

                          return (
                            <Checkbox
                              key={Number(i)}
                              value={Number(i)}
                              labelClass="text-sm"
                              id={`repeat_interval_days[${i}]`}
                              name={`repeat_interval_days[${i}]`}
                              text={`${days}`}
                              onChange={() =>
                                commonHandleCheckBoxFunction(
                                  isChecked,
                                  values.repeat_interval_days,
                                  i,
                                  setFieldValue,
                                  setIntervalDays
                                )
                              }
                              disabled={isDisabled || isDateEditable}
                              parentClass={isDisabled ? 'cursor-not-allowed' : ''}
                              check={isDisabled ? false : isChecked}
                            />
                          );
                        })}
                        <ErrorMessage name="repeat_interval_days" />
                      </div>
                    </div>
                  </div>
                  {/* this is static but will be dynamic in future */}
                  <div className="cms-button half simple bg-transparent">
                    <DatePicker
                      disabled={isDateEditable}
                      startDatePlaceholder={t(
                        'CourseManagement.AddEditForm.StartTimePlaceholder'
                      )}
                      endDatePlaceholder={t(
                        'CourseManagement.AddEditForm.EndTimePlaceholder'
                      )}
                      selectedDate={
                        values?.start_time ? new Date(values?.start_time) : null
                      }
                      endingDate={
                        values?.end_time ? new Date(values?.end_time) : null
                      }
                      startDateName="start_time"
                      endDateName="end_time"
                      isCompulsory
                      icon
                      dateFormat="h:mm aa"
                      range
                      onRangeChange={(startDate, endDate) => {
                        const endTime =
                          startDate >= endDate
                            ? new Date(startDate.getTime() + 3600 * 1000)
                            : endDate;

                        setFieldValue(`start_time`, startDate);
                        setStartTime(startDate);
                        setEndTime(endTime);
                        setFieldValue(`end_time`, endTime);
                        setStartTime(startDate);
                        setEndTime(endTime);
                      }}
                      label={t('CourseManagement.AddEditForm.TimeLabel')}
                      isTimePicker
                      showTimeSelectOnly
                      timeInterval={25}
                      startDateMinTime={new Date(new Date().setHours(0, 0, 0))}
                      endDateMinTime={
                        values?.start_time
                          ? new Date(
                              Number(new Date(values?.start_time).getTime() ?? 0) +
                                1 * 60 * 60 * 1000
                            )
                          : undefined
                      }
                      endDateMaxTime={new Date(new Date().setHours(23, 59, 59))}
                    />
                  </div>
                  {isAdmin && (
                    <ReactSelect
                      options={invitedEditors ?? []}
                      placeholder={t(
                        'CourseManagement.AddEditForm.InvitedEditorPlaceholder'
                      )}
                      disabled={!(invitedEditors && invitedEditors?.length > 0)}
                      label={t('CourseManagement.AddEditForm.InvitedEditorLabel')}
                      name="editor_teacher_id"
                      parentClass="width-full"
                      isMulti
                    />
                  )}
                  <div className="cms-button half simple bg-transparent">
                    <InputField
                      type="number"
                      min={0}
                      name="min_participants"
                      label={t('CourseManagement.AddEditForm.MinParticipantsLabel')}
                      value={values.min_participants}
                      placeholder="00"
                    />
                    <InputField
                      isCompulsory
                      min={1}
                      type="number"
                      name="max_participants"
                      label={t('CourseManagement.AddEditForm.MaxParticipantsLabel')}
                      value={values.max_participants}
                      placeholder="00"
                    />
                  </div>
                  <div className="bnt-wrap">
                    <Button
                      variants="black"
                      className="w-fit"
                      type="submit"
                      isLoading={isLoading}
                      disabled={isLoading}
                    >
                      {t('CourseManagement.AddEditForm.NextButtonText')}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </Card>
        );
      }}
    </Formik>
  );
};

export default InPersonStepOne;
