import Button from 'components/Button/Button';
import Checkbox from 'components/FormElement/CheckBox';
import DropZone from 'components/FormElement/DropZoneField';
import { fileInputEnum } from 'components/FormElement/enum';
import ReactSelect from 'components/FormElement/ReactSelect';
import TextArea from 'components/FormElement/TextArea';
import { IOptions } from 'components/FormElement/types';
import Image from 'components/Image';
import { useTranslation } from 'react-i18next';
import { SetFieldValue } from 'types';
import {
  EnumFileType,
  quizFormInitialValues,
  QuizType,
  QuizTypeOptions,
} from '../../constants';
import { QuestionData, QuizFieldArrayProps } from '../types';
import Options from './Options';

const QuizFieldArray = ({
  data,
  setFieldValue,
  remove,
  push,
  isEdit,
}: QuizFieldArrayProps) => {
  const { t } = useTranslation();

  const handleCheckBoxChanges = (
    setFieldValue: SetFieldValue,
    quiz: QuestionData,
    index: number
  ) => {
    setFieldValue(`data[${index}].is_active`, !quiz.is_active);

    const updatedOtpions = quiz.question_options?.map((item) => ({
      ...item,
      is_active: !quiz.is_active,
    }));
    setFieldValue(`data[${index}].question_options`, updatedOtpions);
  };

  return (
    <>
      {data.map((quiz, index) => (
        <div
          className="module-add-filed-item"
          key={`UniqueKey_question${index + 1}`}
        >
          <span className="module-add-filed-counter">{index + 1}</span>
          <div className="module-add-filed-inner">
            <div className="module-add-question-wrap">
              {quiz.question_type === QuizType.FILL_IN_THE_BLANK && (
                <Button
                  className="h-fit"
                  variants="black"
                  onClickHandler={() =>
                    setFieldValue(
                      `data[${index}].question`,
                      `${quiz.question} ______ `
                    )
                  }
                >
                  {t('QuizCreation.Button.AddBlank')}
                </Button>
              )}
              <TextArea
                parentClass="module-add-question-text input-wrap"
                name={`data[${index}].question`}
                placeholder={t('QuizCreation.Placeholder.Question')}
              />
              <DropZone
                fileInputIcon="linkIcon"
                name={`data[${index}].question_attachment_url`}
                setValue={setFieldValue}
                value={quiz?.question_attachment_url ?? ''}
                isCompulsory
                Title={t('QuizCreation.Label.UploadFile')}
                variant={fileInputEnum.CommentFileInput}
                parentClass="module-add-question-file module-add-question-file--secondary"
                fileType={[EnumFileType.Image, EnumFileType.Video]}
              />

              <ReactSelect
                name={`data[${index}].question_type`}
                options={QuizTypeOptions}
                selectedValue={quiz.question_type}
                onChange={(value) => {
                  setFieldValue(
                    `data[${index}].question_type`,
                    (value as IOptions).value
                  );
                  if ((value as IOptions).value === QuizType.TRUE_FALSE) {
                    setFieldValue(`data[${index}].question_options`, []);
                  } else if ((value as IOptions).value === QuizType.SHORT_ANSWER) {
                    setFieldValue(
                      `data[${index}].question_options`,
                      quizFormInitialValues?.data?.[0].question_options?.map(
                        (item) => ({ ...item, is_correct: true })
                      )
                    );
                  } else {
                    setFieldValue(
                      `data[${index}].question_options`,
                      quizFormInitialValues?.data?.[0].question_options
                    );
                  }
                }}
                isCompulsory
                placeholder={t('QuizCreation.Placeholder.SelectQuestionType')}
                parentClass="module-add-question-select"
                disabled={isEdit && !!quiz.id}
              />

              {/* {questionLength > 1 ? (
                  <Button
                    isIcon
                    variants="Red"
                    onClickHandler={() => {
                      if (quiz.id) {
                        setFieldValue(`data[${index}].is_active`, !quiz.is_active);
                      } else {
                        remove(index);
                      }
                    }}
                  >
                    <Image iconName="trashIcon" />
                  </Button>
                ) : (
                  <></>
                )} */}

              {quiz.id ? (
                <Checkbox
                  check={quiz.is_active}
                  name={`data[${index}].is_active`}
                  onChange={() => {
                    handleCheckBoxChanges(setFieldValue, quiz, index);
                  }}
                />
              ) : (
                <></>
              )}

              {data.length > 1 ? (
                !quiz.id && (
                  <Button isIcon variants="Red" onClickHandler={() => remove(index)}>
                    <Image iconName="trashIcon" />
                  </Button>
                )
              ) : (
                <></>
              )}
            </div>

            {(quiz.question_type === QuizType.MCQ ||
              quiz.question_type === QuizType.FILL_IN_THE_BLANK ||
              quiz.question_type === QuizType.MULTIPLE_ANSWER ||
              quiz.question_type === QuizType.SHORT_ANSWER) && (
              <Options quiz={quiz} index={index} setFieldValue={setFieldValue} />
            )}

            {quiz.question_type === QuizType.TRUE_FALSE && (
              <ReactSelect
                parentClass="mt-4"
                name={`data[${index}].correct_answer`}
                placeholder={t('QuizCreation.Placeholder.TrueFalse')}
                options={[
                  {
                    label: t('QuizCreation.Label.True'),
                    value: 'TRUE',
                  },
                  {
                    label: t('QuizCreation.Label.False'),
                    value: 'FALSE',
                  },
                ]}
                isCompulsory
              />
            )}

            {quiz.question_type === QuizType.ARRANGE_ORDER && (
              <Options
                isOrder
                quiz={quiz}
                index={index}
                setFieldValue={setFieldValue}
              />
            )}
          </div>
        </div>
      ))}

      <Button
        isIcon
        variants="PrimaryWood"
        onClickHandler={() => push(quizFormInitialValues?.data?.[0])}
      >
        {t('QuizCreation.Button.AddQuestion')}
        <Image iconName="plus" />
      </Button>
    </>
  );
};

export default QuizFieldArray;
