// ** Components **
import Button from 'components/Button/Button';
import Card from 'components/Card';
import DropZone from 'components/FormElement/DropZoneField';
import InputField from 'components/FormElement/InputField';
import RadioButtonGroup from 'components/FormElement/RadioInput';
import Image from 'components/Image';
import ReactEditor from 'components/ReactQuillEditor/ReactQuillEditor';
import CourseTeachingMediaList from './CourseTeachingMediaList';
import WeekOverview from './WeekOverview';

// ** Constants & Enums **
import { EnumFileType, MaterialType } from 'components/FormElement/enum';
import { Roles } from 'constants/common.constant';

// ** Form Libraries **
import { Form, Formik } from 'formik';

// ** Hooks **
import { useAxiosGet } from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// ** Utilities **
import {
  processCourseContentFormData,
  removeEmptyKeys,
  updateParamActiveStep,
} from '../../helper/form.helper';

// ** Redux and Slice **
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'reduxStore/slices/authSlice';

// ** Types & Validation **
import {
  CourseMaterialFile,
  CourseMaterialProps,
  CourseMaterialResponse,
  CourseMaterials,
  WeekData,
} from '../../types/courseContentManager.types';
import { CourseMaterialSchema } from '../../validations/courseContentManager.validation';

const CourseContentManager = ({ onSubmit, isLoading }: CourseMaterialProps) => {
  // ** Hooks **
  const { slug } = useParams();
  const [getEditData, { isLoading: gettingEditData }] = useAxiosGet();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userFromRedux = useSelector(getCurrentUser)?.role?.role ?? Roles.Admin;
  // ** State Management **
  const [teachingMaterial, setTeachingMaterial] = useState<CourseMaterialFile[]>([]);
  const [practiceMaterial, setPracticeMaterial] = useState<CourseMaterialFile[]>([]);
  const [initialValues, setInitialValues] = useState<CourseMaterials>();
  const [courseMaterials, setCourseMaterials] = useState<CourseMaterialResponse>();

  // Fetch Edit data for set initial Value.
  const fetchEditData = async () => {
    const { data } = await getEditData(`/courses/course-material`, {
      params: {
        ...(slug ? { slug } : {}),
      },
    });
    if (data) {
      setCourseMaterials(data);
      const nonEmptyData = removeEmptyKeys(data);
      const { course_material, ...courseData } = nonEmptyData ?? {};

      const practice_material: string[] = [];
      const teaching_material: string[] = [];

      course_material?.forEach((item: CourseMaterialFile) => {
        if (item?.material_type === MaterialType.Practice) {
          practice_material.push(item.material_media_url);
          setPracticeMaterial((prev) => [...prev, item]);
        } else if (item?.material_type === MaterialType.Teaching) {
          teaching_material.push(item.material_media_url);
          setTeachingMaterial((prev) => [...prev, item]);
        }
      });

      let course_week_data = [];
      if (courseData?.course_weeks) {
        if (courseData?.course_weeks?.length > 0) {
          course_week_data = courseData?.course_weeks.map(
            (item: WeekData, course_week_index: number) => ({
              ...item,
              sort_order: course_week_index + 1,
              is_active: true,
              ...(item.course_week_media.length > 0
                ? {
                    course_week_media: item.course_week_media,
                  }
                : {
                    course_week_media: [
                      {
                        title: '',
                        link: '',
                        description: '',
                        sort_order: 1,
                        is_active: true,
                      },
                    ],
                  }),
            })
          );
        }
      }

      const initialValuesData = {
        practice_materials: practice_material ?? [],
        teaching_materials: teaching_material ?? [],
        course_material_media: courseData?.course_material_media ?? {
          description: '',
          note: '',
          video_link: '',
        },
        is_student_roster: String(courseData?.is_student_roster ?? ''),
        course_teaching_media:
          Array.isArray(courseData?.course_teaching_media) &&
          courseData?.course_teaching_media.length > 0
            ? courseData?.course_teaching_media
            : [{ title: '', link: '', sort_order: 1, is_active: true }],
        course_weeks: course_week_data,
      };

      setInitialValues(initialValuesData);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchEditData();
    }
  }, [slug]);

  return (
    <>
      {gettingEditData && !initialValues ? (
        <Image loaderType="SiteLoader" />
      ) : (
        initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={CourseMaterialSchema(t)}
            onSubmit={(value) => {
              const processedFormData = processCourseContentFormData(
                value,
                teachingMaterial,
                practiceMaterial
              );

              onSubmit(processedFormData);
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <Card isGray className="course-inner-card">
                    <div className="course-card-title">
                      {t('CourseContentManager.Form.Title.PracticeMaterial')}
                    </div>
                    <div className="row">
                      <div className="left-part">
                        <DropZone
                          fileInputIcon="filePDF"
                          className="row"
                          name="practice_materials"
                          setValue={setFieldValue}
                          value={values?.practice_materials}
                          label={t(
                            'CourseContentManager.Form.Label.PracticeMaterialUpload'
                          )}
                          SubTitle={t(
                            'CourseContentManager.Form.SubTitle.PracticeMaterialUpload'
                          )}
                          isMulti
                          fileType={[EnumFileType.Document]}
                        />
                      </div>
                      <div className="right-part">
                        <InputField
                          isCompulsory
                          name="course_material_media.description"
                          label={t(
                            'CourseContentManager.Form.Label.PracticeDescription'
                          )}
                          placeholder={t(
                            'CourseContentManager.Form.Placeholder.PracticeDescription'
                          )}
                        />
                        <InputField
                          isCompulsory
                          name="course_material_media.video_link"
                          label={t('CourseContentManager.Form.Label.VideoLink')}
                          placeholder={t(
                            'CourseContentManager.Form.Placeholder.VideoLink'
                          )}
                        />
                        <ReactEditor
                          name="course_material_media.note"
                          label={t('CourseContentManager.Form.Label.Notes')}
                          setFieldValue={setFieldValue}
                          value={values?.course_material_media?.note}
                        />
                      </div>
                    </div>
                    {values?.course_weeks && (
                      <WeekOverview
                        setFieldValue={setFieldValue}
                        course_weeks={values?.course_weeks}
                      />
                    )}
                  </Card>
                  <Card isGray className="course-inner-card">
                    <div className="course-card-title">
                      {t('CourseContentManager.Form.Title.TeachingMaterials')}
                    </div>
                    <div className="row">
                      <div className="left-part">
                        <DropZone
                          name="teaching_materials"
                          setValue={setFieldValue}
                          value={values?.teaching_materials}
                          label={t(
                            'CourseContentManager.Form.Label.TeachingMaterialUpload'
                          )}
                          SubTitle={t(
                            'CourseContentManager.Form.SubTitle.TeachingMaterialUpload'
                          )}
                          fileInputIcon="filePDF"
                          isMulti
                          fileType={[EnumFileType.Document]}
                        />
                      </div>
                      <div className="right-part">
                        <RadioButtonGroup
                          optionWrapper="flex gap-4 [&>.radio-option]:w-fit flex-row"
                          isCompulsory
                          name="is_student_roster"
                          options={[
                            {
                              label: t('CourseContentManager.Form.Option.Yes'),
                              value: 'true',
                            },
                            {
                              label: t('CourseContentManager.Form.Option.No'),
                              value: 'false',
                            },
                          ]}
                          label={t(
                            'CourseContentManager.Form.Label.IncludeStudentRoster'
                          )}
                        />
                        <CourseTeachingMediaList
                          setFieldValue={setFieldValue}
                          course_teaching_media={values?.course_teaching_media}
                        />
                      </div>
                    </div>
                  </Card>
                  <div className="btn-wrap">
                    <Button
                      variants="PrimaryWoodBorder"
                      onClickHandler={() => updateParamActiveStep(false, navigate)}
                    >
                      {t('CourseManagement.AddEditForm.PreviousButtonText')}
                    </Button>
                    <Button
                      variants="PrimaryWood"
                      onClickHandler={() => {
                        navigate(
                          `/courses/view/${courseMaterials?.slug}?course_type=${courseMaterials?.type.type}`
                        );
                      }}
                    >
                      {t('CourseManagement.AddEditForm.PreviewButtonText')}
                    </Button>
                    <Button variants="black" type="submit" isLoading={isLoading}>
                      {userFromRedux === Roles.Teacher
                        ? t('Common.Button.Submit')
                        : t('CourseManagement.AddEditForm.NextButtonText')}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )
      )}
    </>
  );
};

export default CourseContentManager;
