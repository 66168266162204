import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { updateParamActiveStep } from '../../helper/form.helper';
import LessonListing from '../selfPaced/components/LessonListing';

const MiniCourseMaterial = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <LessonListing />

      <div className="btn-wrap">
        <Button
          variants="PrimaryWoodBorder"
          onClickHandler={() => updateParamActiveStep(false, navigate)}
        >
          {t('CourseManagement.AddEditForm.PreviousButtonText')}
        </Button>
        <Button variants="PrimaryWood">
          {t('CourseManagement.AddEditForm.PreviewButtonText')}
        </Button>
        <Button variants="blackBorder">
          {t('CourseManagement.AddEditForm.DraftButtonText')}
        </Button>
        <Button
          variants="black"
          onClickHandler={() => {
            updateParamActiveStep(true, navigate);
          }}
        >
          {t('CourseManagement.AddEditForm.NextButtonText')}
        </Button>
      </div>
    </>
  );
};

export default MiniCourseMaterial;
