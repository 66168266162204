import { useSortable } from '@dnd-kit/sortable';
import { SortableOptionProps } from '../types';

const SortableItem = ({
  id,
  text,
  disableDrag,
  isPreviewEdit,
}: SortableOptionProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    disabled: disableDrag,
  });
  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
  };
  return text === '' ? (
    <></>
  ) : (
    <div
      ref={setNodeRef}
      // dragable-item
      className={`dragable-modal-item px-4 py-3 rounded-5px leading-none bg-white block w-full border border-solid border-LightWood/80 text-base text-black ${isPreviewEdit && 'correct'}`}
      {...attributes}
      style={style}
      {...listeners}
    >
      <div id={id}>{text ?? ''}</div>
    </div>
  );
};

export default SortableItem;
