import Button from 'components/Button/Button';
import ChevronLeft from 'components/Icon/assets/ChevronLeft';

interface CardProps {
  title?: string;
  children?: React.ReactNode;
  headerExtra?: React.ReactNode;
  className?: string;
  headerClass?: string;
  bodyClass?: string;
  bigTitle?: boolean;
  isGray?: boolean;
  minimal?: boolean;
  backArrow?: boolean;
  onClickData?: () => void;
}

const Card = ({
  title,
  children,
  headerExtra,
  className,
  isGray,
  minimal,
  bigTitle,
  headerClass,
  bodyClass,
  backArrow,
  onClickData,
}: CardProps) => {
  return (
    <div
      className={`c-card rounded-lg  ${className || ''} 
      ${isGray ? 'bg-LightGray ' : ' bg-white shadow-md'} 
      ${minimal ? 'card-minimal' : ''}`}
    >
      {title || headerExtra ? (
        <div
          className={`c-card__header p-5 pb-3.5 rounded-t-lg relative mb-5 before:absolute before:content-[""] ${headerClass} 
          ${headerExtra ? 'flex justify-between' : ''}
          ${minimal ? 'before:opacity-0 p-5 pb-0 mb-2' : ' before:w-[calc(100%_-_40px)] before:mx-auto before:h-px before:left-0 before:right-0 before:bg-black before:bottom-0 before:opacity-10'}
          `}
        >
          {title ? (
            <div
              className={`c-card__title flex items-center gap-[14px] leading-none ${bigTitle ? 'text-black text-[28px] font-semibold leading-12' : ' text-lg font-semibold'}`}
            >
              {backArrow && (
                <Button
                  className="w-6 h-6 rounded-full bg-PrimaryWood flex items-center justify-center"
                  onClickHandler={onClickData}
                >
                  <ChevronLeft className="w-4 h-4 text-white" />
                </Button>
              )}
              <span>{title}</span>
            </div>
          ) : (
            ''
          )}
          {headerExtra ? (
            <div className="c-card__header-extra">{headerExtra}</div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      {children ? (
        <div
          className={`c-card__body px-5 ${!title && !headerExtra ? ' py-5' : ' pb-5'} ${bodyClass || ''}`}
        >
          {children}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Card;
