export const toTitleCase = (str: string): string => {
  // Define common words to ignore in title case (except the first and last word)
  const lowerCaseWords = [
    'a',
    'an',
    'and',
    'but',
    'for',
    'if',
    'in',
    'nor',
    'of',
    'on',
    'or',
    'so',
    'the',
    'to',
    'up',
    'yet',
    'with',
  ];

  return str
    .split(' ') // Split the string into words
    .map((word, index, arr) =>
      // Capitalize the first and last word
      index === 0 || index === arr.length - 1
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : // Capitalize the word only if it's not a "lowercase word"
          lowerCaseWords.includes(word.toLowerCase())
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' '); // Join the words back into a string
};
