import { TFunction } from 'i18next';
import * as Yup from 'yup';

const CourseVisibilitySchema = (t: TFunction<'translation', undefined>) => {
  return Yup.object().shape({
    is_public: Yup.string(),
    can_teacher_view: Yup.string(),
    visibility_to: Yup.string(),
    user_ids: Yup.mixed().when('visibility_to', {
      is: (value: string) => {
        return value && value.trim() !== '';
      },
      then: () =>
        Yup.array().min(1, t('CourseVisibility.Validation.AtLeastOneUser')),
    }),
    is_at_least_one: Yup.boolean().test(
      'at_least_one',
      t('CourseVisibilitySetting.Validation.AtLeastOneField'),
      (value) => {
        if (!value) {
          return new Yup.ValidationError(
            t('CourseVisibilitySetting.Validation.AtLeastOneField'),
            {},
            `is_at_least_one`
          );
        }
        return true;
      }
    ),
  });
};

export default CourseVisibilitySchema;
