import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Button from 'components/Button/Button';
import Image from 'components/Image';
import { FieldArray } from 'formik';
import { t } from 'i18next';
import { useState } from 'react';
import { CourseTeachingMediaListPorps } from '../../types/courseContentManager.types';
import CourseTeachingMediaItem from './CourseTeachingItem';

const CourseTeachingMediaList = ({
  course_teaching_media,
  setFieldValue,
}: CourseTeachingMediaListPorps) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const [activeId, setActiveId] = useState<number | string>();
  //  ** Commented useState and setState value.
  // const [teachingMediaList, setTeachingMediaList] =
  //   useState<Array<CourseTeachingMedia>>(course_teaching_media);

  // useEffect(() => {
  //   setTeachingMediaList(course_teaching_media);
  // }, [course_teaching_media]);

  const teachingMediaSortItem = course_teaching_media.map((item) =>
    Number(item.sort_order)
  );

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    if (active.id !== over?.id) {
      const oldIndex = course_teaching_media?.findIndex(
        (item) => item.sort_order === Number(active.id)
      );
      const newIndex = course_teaching_media?.findIndex(
        (item) => item.sort_order === Number(over?.id ?? 0)
      );

      const newOptionsData = [...course_teaching_media];
      const [draggedItem] = newOptionsData.splice(oldIndex, 1);
      newOptionsData.splice(newIndex, 0, draggedItem);
      const updatedList = newOptionsData.map((item, index) => ({
        ...item,
        sort_order: index + 1,
      }));

      setFieldValue('course_teaching_media', updatedList);
    }
    setActiveId(undefined);
  };
  //   TODO:- When input field is updated after it being sorted through dnd it set back to initial state (here useState is also removed and map is hitting directly on formValue-> course_teaching_media).

  return (
    <DndContext
      onDragStart={(e) => {
        setActiveId(e?.active?.id);
      }}
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
    >
      <FieldArray name="course_teaching_media">
        {({ push, remove }) => (
          <>
            <SortableContext
              items={teachingMediaSortItem}
              strategy={verticalListSortingStrategy}
            >
              {course_teaching_media?.map((item, key) => (
                <CourseTeachingMediaItem
                  index={key}
                  courseMediaLength={course_teaching_media.length}
                  key={`Course_teaching_media_item${key + 1}`}
                  remove={remove}
                  sort_order={Number(item.sort_order)}
                />
              ))}
            </SortableContext>
            <DragOverlay>
              {activeId && (
                <CourseTeachingMediaItem
                  courseMediaLength={course_teaching_media.length}
                  remove={remove}
                  index={
                    Number(
                      course_teaching_media.find(
                        (item) => item.sort_order === activeId
                      )?.sort_order
                    ) - 1
                  }
                  sort_order={Number(
                    course_teaching_media.find(
                      (item) => item.sort_order === activeId
                    )?.sort_order
                  )}
                />
              )}
            </DragOverlay>
            <Button
              isIcon
              className="w-full"
              variants="PrimaryWood"
              onClickHandler={() =>
                push({
                  title: '',
                  link: '',
                  sort_order: course_teaching_media.length + 1,
                })
              }
            >
              <Image iconName="plus" />
              {t('CourseContentManager.Form.Button.AddMaterial')}
            </Button>
          </>
        )}
      </FieldArray>
    </DndContext>
  );
};

export default CourseTeachingMediaList;
