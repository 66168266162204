export enum NotificationType {
  UPCOMING_COURSE = 'upcoming_course',
  NEW_COURSE = 'new_course',
  COMMUNITY_DISCUSSION = 'community_discussion',
  COMMUNITY_TOPIC = 'community_topic',
  DICTIONARY = 'dictionary',
  LOGIN = 'login',
  ACCOUNT_UPDATE = 'account_update',
  COMMUNITY_FEED = 'community_feed',
  OPERATIONAL = 'operational',
  COMMUNITY_REPORT = 'community_report',
  SUBSCRIPTION = 'subscription',
  NEW_USER_SIGNUP = 'user_management',
  COURSE_REQUEST = 'courses_request',
  FEEDBACK = 'feedback',
}
export interface Notification {
  id: string;
  title: string;
  message: string;
  type: string;
  slug: string;
  language: string;
  meta_data?: {
    user_id?: string;
    community_type?: string;
    community_id?: string;
    common_id?: string;
    course_id?: string;
    language_id?: string;
    slug?: string;
    course_type?: string;
    role_name?: string;
  };
  parent_table_id: string | null;
  is_read: boolean;
  user_id: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}
