// ** Components **
import Button from 'components/Button/Button';
import Checkbox from 'components/FormElement/CheckBox';
import CreatableReactSelect from 'components/FormElement/CreatableReactSelect';
import DropZone from 'components/FormElement/DropZoneField';
import ErrorMessage from 'components/FormElement/ErrorMessage';
import InputField from 'components/FormElement/InputField';
import ReactSelect from 'components/FormElement/ReactSelect';
import Image from 'components/Image';
import ArrangeOrder from './ArrangeOrder';

// ** Constants & Enums **
import { EnumFileType } from 'components/FormElement/enum';
import { QuizType } from '../../constants';

// ** Hooks **
import { FC, useEffect, useState } from 'react';

// ** Types **
import { IOptions } from 'components/FormElement/types';
import { OptionProps, QuestionOption } from '../types';

// ** Utils **
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { SetFieldValue } from 'types';
import '../../index.css';

const Options: FC<OptionProps> = ({ quiz, index, setFieldValue, isOrder }) => {
  const reactOptions = quiz?.question_options?.map((item) => ({
    label: item.option_text,
    value: item.option_text,
  }));

  const blankOptions: { [key: number]: IOptions[] } = {};

  quiz?.question_options?.forEach((item, index) => {
    blankOptions[index] =
      item?.blank_options?.map((blank) => ({
        label: blank,
        value: blank,
      })) ?? [];
  });

  const [quizOptions, setQuizOptions] = useState(reactOptions);
  const [blankOptionArray, setBlankOptionArray] = useState<{
    [key: number]: IOptions[];
  }>(blankOptions);
  const { t } = useTranslation();
  const quizLength = quiz?.question_options?.length ?? 0;
  useEffect(() => {
    if (reactOptions?.length) {
      const quizAnsArray = Array.isArray(quiz.correct_answer)
        ? quiz.correct_answer
        : [quiz.correct_answer];
      const reactOptions = quiz?.question_options?.map((item) => ({
        label: item.option_text,
        value: item.option_text,
      }));

      setQuizOptions(reactOptions);
      const data = reactOptions?.find((item) => quizAnsArray.includes(item.value));
      if (!data) {
        setFieldValue(`data[${index}].correct_answer`, '');
      }
    }
  }, [quiz?.question_options]);

  const handleCreateOption = (
    value: string,
    setFieldValue: SetFieldValue,
    oldKeys: string[],
    optionIndex: number
  ) => {
    setBlankOptionArray((prev) => ({
      ...prev,
      [optionIndex]: [{ label: value, value }, ...(prev?.[optionIndex] ?? [])],
    }));
    setFieldValue(`data[${index}].question_options[${optionIndex}].blank_options`, [
      value,
      ...oldKeys,
    ]);
    setFieldValue(
      `data[${index}].question_options[${optionIndex}].option_text`,
      JSON.stringify([value, ...oldKeys])
    );
  };

  const hanldeDeleteOption = (
    setFieldValue: SetFieldValue,
    option: QuestionOption,
    e: IOptions[],
    optionIndex: number
  ) => {
    const blankOption = option?.blank_options?.filter(
      (item) => item !== e?.[0].value
    );
    setFieldValue(
      `data[${index}].question_options[${optionIndex}].blank_options`,
      blankOption
    );
    setFieldValue(
      `data[${index}].question_options[${optionIndex}].option_text`,
      JSON.stringify(blankOption)
    );
  };

  return (
    <FieldArray name={`data[${index}].question_options`}>
      {({ remove, push }) => (
        <>
          {quiz.question_options && quiz?.question_options?.length > 0 && (
            <>
              <div className="module-add-question-option-wrap">
                <div
                  className={`module-add-question-option ${quiz.question_type === QuizType.SHORT_ANSWER ? 'grid-cols-1' : ''}`}
                >
                  {quiz.question_options.map((option, optionIndex) => {
                    return (
                      <div
                        key={`Quiz_options${index}question${optionIndex + 1}`}
                        className="comment-input-wrap"
                      >
                        {quiz.question_type === QuizType.FILL_IN_THE_BLANK ? (
                          <>
                            <CreatableReactSelect
                              options={blankOptionArray?.[optionIndex] ?? []}
                              name={`data[${index}].question_options[${optionIndex}].blank_options`}
                              isCompulsory
                              placeholder={t('QuizCreation.Placeholder.Option', {
                                number: optionIndex + 1,
                              })}
                              isMulti
                              parentClass="input-wrap"
                              onChange={(e) => {
                                hanldeDeleteOption(
                                  setFieldValue,
                                  option,
                                  e as IOptions[],
                                  optionIndex
                                );
                              }}
                              handleCreateOption={(e) => {
                                handleCreateOption(
                                  e,
                                  setFieldValue,
                                  option.blank_options ?? [],
                                  optionIndex
                                );
                              }}
                            />
                            <ErrorMessage
                              name={`data[${index}].question_options[${optionIndex}].option_text`}
                            />
                          </>
                        ) : (
                          <>
                            <InputField
                              parentClass="input-wrap"
                              name={`data[${index}].question_options[${optionIndex}].option_text`}
                              placeholder={t('QuizCreation.Placeholder.Option', {
                                number: optionIndex + 1,
                              })}
                              value={option.option_text}
                            />
                            {quiz.question_type === QuizType.MCQ ||
                            quiz.question_type === QuizType.MULTIPLE_ANSWER ? (
                              <DropZone
                                isCompulsory
                                name={`data[${index}].question_options[${optionIndex}].option_attachment_url`}
                                setValue={(name, File) => {
                                  setFieldValue(name, File);
                                  if (option.option_text === '') {
                                    setFieldValue(
                                      `data[${index}].question_options[${optionIndex}].option_text`,
                                      `Image ${optionIndex + 1}`
                                    );
                                  }
                                }}
                                acceptTypes="image/*,video/*"
                                value={option?.option_attachment_url ?? ''}
                                variant="commentFileInput"
                                fileInputIcon="linkIcon"
                                fileType={[EnumFileType.Image, EnumFileType.Video]}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                        {/* {(option.id && option.is_correct) ||
                        (quiz.question_options && quizLength <= 4) ? (
                          <></>
                        ) : (
                          <Button
                            isIcon
                            variants="Red"
                            onClickHandler={() => {
                              if (option.id) {
                                setFieldValue(
                                  `data[${index}].question_options[${optionIndex}].is_active`,
                                  !option.is_active
                                );
                              } else {
                                remove(optionIndex);
                              }
                            }}
                          >
                            <Image iconName="trashIcon" />
                          </Button>
                        )} */}

                        {option.id &&
                        quiz.question_type !== QuizType.MCQ &&
                        quiz.question_type !== QuizType.FILL_IN_THE_BLANK &&
                        quiz.question_type !== QuizType.MULTIPLE_ANSWER ? (
                          <Checkbox
                            check={option.is_active ?? false}
                            name={`data[${index}].question_options[${optionIndex}].is_active`}
                            onClick={() =>
                              setFieldValue(
                                `data[${index}].question_options[${optionIndex}].is_active`,
                                !option.is_active
                              )
                            }
                            disabled={!quiz.is_active}
                          />
                        ) : (
                          <></>
                        )}
                        {option.is_correct && option.id ? (
                          <></>
                        ) : (
                          <>
                            {quizLength > 4 ||
                            (quiz.question_type !== QuizType.MCQ &&
                              quiz.question_type !== QuizType.MULTIPLE_ANSWER &&
                              quiz.question_type !== QuizType.FILL_IN_THE_BLANK &&
                              quiz.question_type !== QuizType.ARRANGE_ORDER &&
                              quizLength > 1) ||
                            (quiz.question_type === QuizType.ARRANGE_ORDER &&
                              quizLength > 2) ? (
                              <>
                                {option.id ? (
                                  <></>
                                ) : (
                                  <Button
                                    isIcon
                                    variants="Red"
                                    onClickHandler={() => {
                                      if (
                                        quiz?.question_options?.length &&
                                        quiz?.question_options?.length > 1
                                      ) {
                                        remove(optionIndex);
                                      }
                                    }}
                                  >
                                    <Image iconName="trashIcon" />
                                  </Button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="module-add-question-correct">
                  {!isOrder && quiz.question_type !== QuizType.SHORT_ANSWER && (
                    <ReactSelect
                      isCompulsory
                      name={`data[${index}].correct_answer`}
                      options={quizOptions as IOptions[]}
                      isMulti={quiz?.question_type === QuizType.MULTIPLE_ANSWER}
                      disabled={quiz.question_options.some(
                        (item) => !item.option_text || item.option_text === ''
                      )}
                      placeholder={t('QuizCreation.Placeholder.SelectCorrectAnswer')}
                      parentClass="module-add-question-select"
                    />
                  )}
                  {quizLength < 4 ||
                  quiz.question_type === QuizType.SHORT_ANSWER ||
                  quiz.question_type === QuizType.ARRANGE_ORDER ? (
                    <Button
                      isIcon
                      variants="PrimaryWood"
                      onClickHandler={() => {
                        push({
                          option_text: '',
                          ...(quiz.id ? { question_id: quiz.id } : {}),
                          ...(quiz.language_id
                            ? { language_id: quiz.language_id }
                            : {}),
                          option_attachment_url: '',
                          is_correct: false,
                          is_active: true,
                          ...(isOrder
                            ? {
                                correct_sequence: quizLength + 1,
                              }
                            : {}),
                        });
                      }}
                    >
                      <Image iconName="plus" />
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {isOrder && (
                <ArrangeOrder
                  orderedOptions={(data) => {
                    setFieldValue(`data[${index}].arranged_options`, data);
                  }}
                  options={quiz.question_options}
                />
              )}
            </>
          )}
        </>
      )}
    </FieldArray>
  );
};

export default Options;
