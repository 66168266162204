import Button from 'components/Button/Button';
import Image from 'components/Image';
import Modal from 'components/Modal';
import { CourseTypeEnum, languageConstant } from 'constants/common.constant';
import { format } from 'date-fns';
import { useAxiosDelete, useAxiosGet, useAxiosPatch } from 'hooks/useAxios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'reduxStore/slices/authSlice';
import { setLanguage, useLanguage } from 'reduxStore/slices/languageSlice';
import { SidebarSelector, toggleSidebar } from 'reduxStore/slices/layoutSlice';
import { socketSelector } from 'reduxStore/slices/socketSlice';
// import { socketSelector } from 'reduxStore/slices/socketSlice';
import store from 'reduxStore/store';
import { logout } from 'utils';
import { Notification, NotificationType } from '../header.type';

const Header = () => {
  const { language, defaultLanguage } = useSelector(useLanguage);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isNotification, setIsNotification] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const { user } = useSelector(getAuth);
  const { t } = useTranslation();

  const isSidebarOpen = useSelector(SidebarSelector);

  const [getApi] = useAxiosGet();
  // const [postApi] =useAxiosPost();
  const [patchApi] = useAxiosPatch();
  const [deleteApi] = useAxiosDelete();
  const navigate = useNavigate();
  const socket = useSelector(socketSelector);

  // const socket = useSelector(socketSelector);

  const handleLogout = async () => {
    await getApi('auth/login', {
      params: {
        status: 'logout',
      },
    });
  };

  const fetchNotifications = async () => {
    try {
      const response = await getApi('/notification');
      setNotifications(response?.data || []);
      setUnreadCount(
        response?.data?.filter(
          (notification: { is_read: string }) => !notification.is_read
        )?.length || 0
      ); // Set unread count from the fetched data
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    fetchNotifications();

    socket?.on('notification-data', (newNotification) => {
      setNotifications((prev) => [newNotification, ...prev]);
      setUnreadCount((prev) => prev + 1);
    });

    return () => {
      socket?.off('notification-data');
    };
  }, [language]); // Dependencies on socket and user ID

  const handleMarkAsRead = async (notificationId: string) => {
    try {
      await patchApi(`/notification/read/${notificationId}`, {}); // Mark as read API call
      setNotifications((prev) =>
        prev.map((notification) =>
          notification.id === notificationId
            ? { ...notification, is_read: true }
            : notification
        )
      );
      setUnreadCount((prev) => (prev > 0 ? prev - 1 : 0));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleClearAll = async () => {
    try {
      await deleteApi('/notification');
      setNotifications([]);
      setUnreadCount(0);
    } catch (error) {
      console.error('Error clearing notifications:', error);
    }
  };

  const getNotificationUrl = (notification: Notification) => {
    const notificationType =
      NotificationType[notification.type as keyof typeof NotificationType];
    const basePath = `/courses/${notification.meta_data?.common_id}/${notification.meta_data?.slug}`;
    const queryString =
      notification.meta_data?.course_type === CourseTypeEnum.SELF_PACED_COURSES
        ? `?lang=${notification.meta_data.language_id}&course_id=${notification.meta_data?.course_id}&step=1`
        : `?course_id=${notification.meta_data?.course_id}&step=1`;
    switch (notificationType) {
      case NotificationType.OPERATIONAL:
      case NotificationType.LOGIN:
      case NotificationType.ACCOUNT_UPDATE:
        return;
      case NotificationType.COMMUNITY_FEED:
        return `/community/${notification.meta_data?.community_id}?community=${notification.meta_data?.community_type}`;
      case NotificationType.NEW_USER_SIGNUP:
        return `/manage-users/${notification.meta_data?.user_id}/${notification.meta_data?.role_name?.toLowerCase()}`;
      case NotificationType.COURSE_REQUEST:
        return `${basePath}${queryString}`;
      case NotificationType.COMMUNITY_DISCUSSION:
        return `/community/${notification.meta_data?.community_id}?community=${notification.meta_data?.community_type}`;
      case NotificationType.COMMUNITY_TOPIC:
        return `/community/${notification.meta_data?.community_id}?community=${notification.meta_data?.community_type}`;
      case NotificationType.DICTIONARY:
        return `/dictionary`;
      case NotificationType.NEW_COURSE:
        return `/courses/view/${notification.meta_data?.slug}/${notification.meta_data?.common_id}?course_type=${notification.meta_data?.course_type}`;
      case NotificationType.FEEDBACK:
        return `/feedback`;
      case NotificationType.UPCOMING_COURSE: // TODO pending fe be
        return ``;
      case NotificationType.SUBSCRIPTION:
        return `/subscription`;
      case NotificationType.COMMUNITY_REPORT:
        return `/reported-comments?community=feed`;
      default:
    }
  };

  const handleNotificationClick = (notification: Notification) => {
    const url = getNotificationUrl(notification);
    if (url) {
      navigate(url);
    }
  };

  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement | null>(null);
  // const isSidebarOpen = useSelector(SidebarSelector);
  return (
    <>
      {/*  sticky top-0 z-3 */}
      <header
        className={`admin-header fixed right-0 top-0 z-[1234] w-full max-w-[calc(100%_-_300px)] transition-all duration-300 ${isSidebarOpen ? '' : ' max-w-[calc(100%_-_95px)]'}`}
      >
        <div className="admin-header__inner  px-5 py-3 flex items-center justify-between bg-white relative z-2 min-h-20">
          <button
            onClick={() => dispatch(toggleSidebar())}
            className={`sidebar-trigger cursor-pointer ${isSidebarOpen ? '' : 'rotate-180'}`}
          >
            <Image iconName="menuIcon" />
          </button>
          <div className="flex items-center ml-auto">
            <div className="flex items-center w-[90px] rounded-full bg-LightGray relative z-1 mr-5">
              <span
                className={`block w-[45px] aspect-square rounded-full absolute top-0 bg-LightWood -z-1 transition-all duration-300 ${
                  language === languageConstant.EN ||
                  (!language && defaultLanguage === languageConstant.EN)
                    ? 'left-0'
                    : 'left-1/2'
                }`}
              />
              <button
                className="w-[45px] aspect-square rounded-full cursor-pointer flex items-center justify-center text-black text-base leading-normal"
                onClick={() =>
                  dispatch(setLanguage({ language: languageConstant.EN }))
                }
              >
                EN
              </button>
              <button
                className="w-[45px] aspect-square rounded-full cursor-pointer flex items-center justify-center text-black text-base leading-normal"
                onClick={() =>
                  dispatch(setLanguage({ language: languageConstant.ES }))
                }
              >
                ES
              </button>
            </div>
            <div className="header-notification-wrap relative mr-2">
              <Button
                onClickHandler={() => setIsNotification(true)}
                className="header-notification  relative h-13 w-13 p-3 rounded-full border border-solid border-LightWood flex items-center justify-center text-black hover:bg-PrimaryWood hover:text-white transition-all duration-300 cursor-pointer"
              >
                <Image iconName="notification" />
                {unreadCount > 0 && (
                  <span className="update absolute top-0 right-0 bg-PrimaryWood text-white leading-none aspect-square px-1 rounded-full text-sm flex items-center justify-center">
                    {unreadCount}
                  </span>
                )}
              </Button>
              {isNotification && (
                <div
                  ref={modalRef}
                  className="header-notification-list-wrap absolute rounded-xl top-[calc(100%_+_20px)] right-0 bg-white py-4 px-5 z-1 w-[500px] drop-shadow-[0_-5px_20px_rgba(0,0,0,0.15)]"
                >
                  <div className="header-notification-list-header flex justify-between pb-3 mb-4 border-b border-solid border-LightGray">
                    <div className="header-notification-title  text-xl font-semibold text-black">
                      {t('Header.Notification.Dialog.Title')}
                    </div>
                    <div
                      className="header-notification-mark  flex items-center gap-1.5 text-PrimaryWood text-sm font-normal cursor-pointer hover:text-black transition-all duration-300 select-none hover:underline"
                      onClick={() => handleClearAll()}
                    >
                      {t('Header.Notification.Dialog.ClearAll')}
                    </div>
                  </div>
                  <div className="notification-items  max-h-[calc(100dvh_-_450px)]  overflow-auto min-h-[70px] transition-all duration-300 scroll-hide">
                    {notifications?.map((notification) => (
                      <div
                        key={notification.id}
                        onClick={() => handleNotificationClick(notification)}
                        className="notification-item  flex relative border-b border-solid border-LightGray pb-3.5 mb-3.5 last:pb-0 last:mb-0 last:border-none cursor-pointer"
                      >
                        <div className="notification-item__img  w-9 h-9 rounded-full">
                          <Image
                            imgClassName="w-full h-full rounded-full object-cover"
                            isFromDataBase={false}
                            src="/images/profile.png"
                          />
                        </div>
                        <div className="notification-item__content max-w-[calc(100%_-_36px)] pl-3 flex-1">
                          <p className="title  text-base text-black leading-12 mb-0.5">
                            {notification.title}
                          </p>
                          <span className="desc  text-sm font-light leading-12 block text-black/70">
                            {notification.message}
                          </span>
                          <span className="duration   mt-1 text-xs font-normal text-PrimaryWood block leading-none">
                            {format(
                              new Date(notification.created_at),
                              'MMMM dd, yyyy hh:mm a'
                            )}
                          </span>
                        </div>
                        {!notification.is_read && (
                          <div
                            className="notification-item__status"
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            {t('Header.Notification.Dialog.MarkAsRead')}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="notification-clear mt-5">
                    <Link
                      className=" block text-PrimaryWood bg-LightGray text-center pt-1 pb-1.5 rounded hover:bg-PrimaryWood hover:text-white transition-all duration-300 active:scale-95"
                      to="#!"
                      onClick={() => handleClearAll()}
                    >
                      {t('Header.Notification.Dialog.ClearAll')}
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="header-user-profile relative">
              <Button
                onClickHandler={() => setIsOpen(true)}
                className="header-profile  w-12 h-12 ml-3 cursor-pointer bg-LightWood border-2 border-solid border-black rounded-full"
              >
                <Image
                  isFromDataBase={!!user?.profile_image}
                  src={user?.profile_image || '/images/no-image.png'}
                  imgClassName=" w-full h-full object-cover rounded-full"
                />
              </Button>
              {isOpen && (
                <div
                  className="header-profile-menu  absolute rounded-xl top-[calc(100%_+_20px)] right-0 bg-white py-4 px-5 z-1 w-[260px] drop-shadow-[0_-5px_20px_rgba(0,0,0,0.15)]"
                  ref={modalRef}
                >
                  <div className="header-profile-menu__title  text-xl font-semibold leading-normal pb-2.5 border-b border-solid border-LightGray">
                    <span>{t(`Header.Profile.Dialog.Title`)}</span>
                  </div>
                  <Link
                    to="/profile"
                    className="header-profile-wrap flex gap-3 py-4 hover:opacity-70 transition-all duration-300"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <div className="header-profile-menu__img  w-9 h-9 rounded-full">
                      <Image
                        isFromDataBase={!!user?.profile_image}
                        src={user?.profile_image || '/images/no-image.png'}
                        imgClassName=" w-full h-full rounded-full object-cover"
                      />
                    </div>
                    <div className="header-profile-name  flex-1 relative pr-30px flex flex-col max-w-[calc(100%_-_48px)]">
                      <span
                        className="name  text-black truncate leading-12 text-base block"
                        title={`${user?.first_name ?? 'Profile'} ${user?.last_name || ''}`}
                      >
                        {user?.first_name ?? 'Profile'} {user?.last_name}
                      </span>
                      <span
                        className="email text-black truncate font-light leading-12 text-sm mt-0.5 block"
                        title="email"
                      >
                        {user?.email}
                      </span>
                      <Image
                        iconName="chevronRight"
                        iconClassName="more-icon  absolute right-0 top-1/2 -translate-y-1/2"
                      />
                    </div>
                  </Link>

                  <Button
                    onClickHandler={async () => {
                      setIsOpen(!isOpen);
                      await handleLogout();
                      await logout(store);
                    }}
                    className="button RedOpacity w-full"
                  >
                    {t(`Header.Profile.Dialog.Signout`)}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <Modal modalEl={modalRef} setIsOpen={setIsOpen} />
      <Modal modalEl={modalRef} setIsOpen={setIsNotification} />
    </>
  );
};

export default Header;
