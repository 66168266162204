// ** DnD Kit Imports **
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

// ** Components **
import { FieldArray } from 'formik';
import WeekCourses from './WeekCourses';

// ** State Management & Types **
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Button from 'components/Button/Button';
import Image from 'components/Image';
import { useAxiosPost } from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WeekData, WeekOverviewProps } from '../../types/courseContentManager.types';

const WeekOverview = ({ course_weeks, setFieldValue }: WeekOverviewProps) => {
  // ** State Management **
  const [weekList, setWeekList] = useState<Array<WeekData>>(course_weeks);
  const [addWeekMedia, { isLoading: isCreatingWeek }] = useAxiosPost();
  const [activeId, setActiveId] = useState<number | string>();
  const { slug } = useParams();
  const weekSortItems = course_weeks.map((item) => Number(item.sort_order));

  const { t } = useTranslation();

  // ** DnD State & Logic **
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const sendWeekData = async () => {
    const { data } = await addWeekMedia(`/courses/create-week`, {
      course_slug: slug,
    });
    return data;
  };

  useEffect(() => {
    setWeekList(course_weeks);
  }, [course_weeks]);

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (active.id !== over?.id) {
      const oldIndex = weekList.findIndex(
        (item) => item.sort_order === Number(active.id)
      );

      const newIndex = weekList.findIndex(
        (item) => item.sort_order === Number(over?.id ?? 0)
      );

      const newOptionsData = [...weekList];
      const [draggedItem] = newOptionsData.splice(oldIndex, 1);
      newOptionsData.splice(newIndex, 0, draggedItem);
      setWeekList(newOptionsData);
      setFieldValue('course_arranged_weeks', newOptionsData);
    }
    setActiveId(undefined);
  };
  //   TODO:- When input field is updated after it being sorted through dnd it set back to initial state.

  return (
    <DndContext
      onDragStart={(e) => setActiveId(e?.active?.id)}
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
      onDragEnd={handleDragEnd}
    >
      <FieldArray name="course_weeks">
        {({ push }) => (
          <>
            <SortableContext
              items={weekSortItems}
              strategy={verticalListSortingStrategy}
            >
              {weekList.map((week, key) => (
                <WeekCourses
                  week={week}
                  setFieldValue={setFieldValue}
                  key={`weekList${key + 1}`}
                />
              ))}
            </SortableContext>
            <DragOverlay>
              {activeId && (
                <WeekCourses
                  week={
                    weekList.find((item) => item.sort_order === activeId) as WeekData
                  }
                  setFieldValue={setFieldValue}
                />
              )}
            </DragOverlay>
            <div className="btn-wrap">
              <Button
                isIcon
                variants="PrimaryWood"
                onClickHandler={async () => {
                  const data = await sendWeekData();
                  push({
                    id: data.id,
                    sort_order: data.sort_order,
                    week_number: data.week_number,
                    is_active: true,
                    course_week_media: [
                      {
                        title: '',
                        link: '',
                        description: '',
                        sort_order: 1,
                        is_active: true,
                      },
                    ],
                  });
                }}
                isLoading={isCreatingWeek}
              >
                <Image iconName="plus" />
                {t('CourseContentManager.Form.Button.AddWeek')}
              </Button>
            </div>
          </>
        )}
      </FieldArray>
    </DndContext>
  );
};

export default WeekOverview;
