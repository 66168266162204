import Header from 'components/AdminLayout/components/Header';
import Loaders from 'components/Loaders';
import { AdminNavigation } from 'constants/navigation.constant';
import { useAxiosGet } from 'hooks/useAxios';
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { SidebarSelector } from 'reduxStore/slices/layoutSlice';
import { currentPageCount } from 'reduxStore/slices/paginationSlice';
import Sidebar from './components/Sidebar';

const AdminLayout = () => {
  const [getApi] = useAxiosGet();
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(SidebarSelector);
  const location = useLocation();

  const googleConnection = async () => {
    if (window.location.href.includes(AdminNavigation.googleAuth.view.path)) {
      const { data } = await getApi(`/auth/google/connect`);
      window.location.href = data;
    }
  };

  useEffect(() => {
    dispatch(currentPageCount({ currentPage: 1 }));
  }, [location.pathname]);

  useEffect(() => {
    googleConnection();
  }, []);

  return (
    <div className="dasboard-layout flex h-100dvh">
      <Sidebar />
      <div
        className={`admin-content  ml-auto transition-all duration-300 bg-LightGray ${isSidebarOpen ? ' w-[calc(100%_-_300px)]' : ' w-[calc(100%_-_95px)]'}`}
      >
        <Header />
        <main className="style-scroll pb-5 bg-LightGray pt-20">
          <Suspense fallback={<Loaders />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
